.App {
  text-align: center;
  padding: 2rem;
}

* {
  box-sizing:border-box;
}

body {
  margin:0;
  padding:0;
  display:flex;
  align-items:center;
  justify-content: center;
  height:85vh;
  overflow:hidden;
  /*scale: 1*/
}

.container {
  width:500px;
  height:500px;
  background-color: #ccc;
  background-color: #2b4764;
  border-radius:50%;
  border:15px solid #dde;
  border:15px solid #2b4764;
  position: relative;
  overflow: hidden;
  transition: 2s;
  box-shadow: 0px 1px 8px 4px rgb(55 57 60 / 50%);
}

.container div {
  height:50%;
  width:230px;
  /*left:135px;*/

  position: absolute;
  clip-path: polygon(100% 0 , 50% 100% , 0 0 );
  transform:translateX(-50%);
  transform-origin:bottom;
  text-align:center;
  display:flex;
  align-items: center;
  justify-content: center;
  font-size:22px;
  font-weight:bold;
  font-family:sans-serif;
  color:#fff;
  box-shadow: inset 0px -4px 20px 5px rgba(50, 50, 0, 0.4);
}

.container p {
  transform: translate(0, -30px) rotate(-90deg);
  user-select: none;
  text-shadow: 2px 2px 13px #000000a1;
}

.container .agroVeg {
  background-color: #3f51b5;
}
.container .metalSid {
  background-color: #9c27b0;
  transform: rotate(45deg);
}
.container .metalMec {
  background-color: #ff9800;
  transform:rotate(90deg);
}
.container .modaAbb {
  background-color: #4caf50;
  transform: rotate(135deg);
}
.container .turismo {
  background-color: #e91e63;
  transform: rotate(180deg);
}
.container .agroAnima {
  background-color: #795548;
  background-color: #fab6b0;
  transform: rotate(225deg);
}
.container .mobileArr {
  background-color: #009688;
  transform: rotate(270deg);
}
.container .commercio {
  background-color: #f44336;
  transform: rotate(315deg);
}

.arrow-wrap {
  filter: drop-shadow(-1px 6px 3px rgba(0, 0, 0, 0.6));
  position: relative;
  top: 30px;
  left: 220px;
  z-index:10;
}

.arrow {
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  color:#fff;
  clip-path: polygon(50% 10%, 0% 100%, 100% 100%);
  background: white;
  padding: 1.6rem;
  border-radius: 2rem;
}


#spin {
  position: relative;
  top:405px;
  left:325px;
  transform:translate(-50%,-50%);
  z-index:10;
  background-color: rgba(81, 135, 188, 1);
  background-color: #2b4764;
  text-transform: uppercase;
  border:8px solid #42688f;
  font-weight:bold;
  font-size:20px;
  color:#FFFFFF;
  width: 80px;
  height:80px;
  font-family: sans-serif;
  border-radius:50%;
  cursor: pointer;
  outline:none;
  letter-spacing: 1.5px;
  box-shadow: 0px 0px 14px 4px rgba(79, 86, 92, 0.7);
}

#arrow-visual {
  animation: bounce 3s ease 0s 3 normal forwards;
}

@keyframes bounce {
  0% {
    animation-timing-function: ease-in;
    opacity: 1;
    transform: translateY(-45px);
  }

  24% {
    opacity: 1;
  }

  40% {
    animation-timing-function: ease-in;
    transform: translateY(-24px);
  }

  65% {
    animation-timing-function: ease-in;
    transform: translateY(-12px);
  }

  82% {
    animation-timing-function: ease-in;
    transform: translateY(-6px);
  }

  93% {
    animation-timing-function: ease-in;
    transform: translateY(-4px);
  }

  25%,
  55%,
  75%,
  87% {
    animation-timing-function: ease-out;
    transform: translateY(0px);
  }

  100% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: translateY(0px);
  }
}
